@import 'variables.scss';

.action-button {
  position: relative;
  padding: 8px;

  &-menu-button {
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
    padding-right: 5px;

    &-dot {
      height: 4px;
      width: 4px;
      background-color: $checked-color;
      border-radius: 50%;
      float: right;
      margin-top: 16px;
      margin-right: 4px;
    }
  }

  &-menu {
    z-index: 1;
    width: 150px;
    position: absolute;
    top: 25px;
    right: 10px;
    background-color: white;
    border-bottom: 1px solid $border-color;
    box-shadow: 0px 2px 10px -2px $border-color;

    &-position {
      border: 1px solid $border-color;
      border-bottom-width: 0px;
      height: 32px;
      line-height: 32px;
      cursor: pointer;
      padding-left: 10px;

      &:hover {
        background-color: $border-color;
      }
    }
  }
}
