/* after changing this file run 'npm run webpack:build' */

/***************************
put Sass variables here:
eg $input-color: red;
****************************/
@import 'node_modules/bootswatch/dist/cerulean/variables';
// Override Bootstrap variables
@import 'bootstrap-variables';
// Import Bootstrap source files from node_modules
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootswatch/dist/cerulean/bootswatch';

@import "node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
