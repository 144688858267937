@import 'variables.scss';

.common-drop-down {
  .required-red {
    color: $now-color;
  }

  .required-red-background {
    .ng-select-container {
      background-color: rgba($now-color, 0.185);
    }
  }

  .name {
    font-size: 12px;
    white-space: nowrap;
    color: $checked-color;
  }

  .ng-select.custom {
    min-height: 0px;
    font-family: Regular;
    border: 0 $now-color;
  }

  .ng-select.custom .ng-select-container {
    min-height: 0px;
    border-radius: 3px;
    border-color: $border-color;
    font-family: Regular;
    font-size: 14px;
    height: 34px;
    cursor: pointer;
  }

  .ng-select.noborder .ng-select-container {
    min-height: 0px;
    border-radius: 3px;
    border: none;
    font-family: Regular;
    font-size: 14px;
    height: 34px;
  }

  .ng-select.custom-height .ng-select-container {
    height: 30px !important;
  }

  //.ng-select.custom .ng-dropdown-panel {
  //  width: 200px;
  //}
}

.common-drop-down-arrow {
  @extend .common-drop-down;

  .ng-select.custom .ng-arrow-wrapper {
    padding-bottom: 3px;
  }

  .ng-select.custom .ng-arrow-wrapper .ng-arrow {
    display: none;
  }

  .ng-select.noborder .ng-arrow-wrapper .ng-arrow {
    display: none;
  }

  .ng-select span.ng-arrow-wrapper:before {
    content: url('/content/icons/ArrowDown.svg');
    display: inline-block;
    margin-left: -15px;
    margin-top: 8px;
  }
}

.hide-arrow {
  .ng-arrow-wrapper {
    display: none;
  }
}
