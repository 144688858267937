@import 'node_modules/@ng-select/ng-select/themes/default.theme.css';
@import 'node_modules/@videogular/ngx-videogular/fonts/videogular.css';
@import 'variables';
@import 'fonts';
@import 'board';
@import 'drop-down';
@import 'modal';
@import 'case-summary';
@import 'action-button';
@import 'snow';

html {
  height: 100%;
}

body {
  background-color: $content-background-color;
  font-family: Regular;
  overflow-x: hidden;
  height: 100%;
  font-size: 14px;
}

.form-group {
  margin-bottom: 1rem;
}

input,
textarea {
  width: 100%;
  border: 1px solid $border-color;
  padding-top: 5px;
  padding-left: 7px;
  border-radius: 2px;
}

input {
  height: 34px;
}

mat-checkbox {
  label {
    margin-bottom: 0;
  }
}

//.mat-checkbox .mat-checkbox-frame {
//  border-color: black !important;
//}

.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #f50a37 !important;
}

.required {
  margin-left: 2px;
  color: $required-field-color;
}

button.primary {
  color: #ffffff !important;
  background-color: $primary-color;
  font-family: Roboto,Helvetica Neue,sans-serif;
  font-size: 14px;
  font-weight: 500;
}

button[disabled][disabled],
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  color: #0a0a0a !important;
  border-color: #cecece !important;
  background-image: linear-gradient(#eaeaea, #eeeeee 60%, #cecece);
  background-repeat: no-repeat;
}

div.ng-input > input[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

td.text-align-center {
  text-align: center;
}

.text-inactive {
  color: $inactive-color;
}

.text-deleted {
  padding-top: 3px;
  color: $primary-color;
}

.popover .popover-header {
  background-color: $primary-color;
  color: white;
  font-weight: 500;
}

.disabled-overlay {
  z-index: 1000;
  display: block;
  position: absolute;
  top: 60px;
  left: 240px;
  width: 100%;
  height: 100%;
  opacity: 0.75;
  background-color: #383838;
}

/* Remove ngb alert close button (×) */
ngb-alert.remove-close-button {
  button.close {
    display: none !important;
  }
}

/* Adjust value and arrow vertical alignment in untouched state */
.ng-select .ng-select-container .ng-value-container {
  padding-top: 4px;
}
.ng-select .ng-arrow-wrapper {
  padding-top: 4px;
}

/* Adjust value and arrow vertical alignment in touched state */
.ng-select-bottom .ng-select-container .ng-value-container {
  padding-top: 15px;
}
.ng-select-bottom .ng-arrow-wrapper {
  padding-top: 15px;
}
.custom-height.ng-select-bottom .ng-arrow-wrapper {
  padding-top: 4px;
}

/* Make date time picker show in modals */
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 2001 !important;
}

/* Make google.maps.places.Autocomplete show in modals */
.pac-container {
  z-index: 1501 !important;
}

.case-economy-invoice-details-modal {
  .modal-xl {
    max-width: 1440px;
  }
}

.case-summary-external-modal {
  .modal-xl {
    max-width: 1500;
  }
}

.cdk-overlay-container .cdk-overlay-pane {
  min-width: max-content;
}
