@import 'variables.scss';

$primary-table-cell-padding-left-size: 7px;
$primary-table-header-cell-border-color: #f8f7f7;
$primary-table-header-cell-selected-background-color: #ffe8ec;

$primary-table-cell-border-color_checked: #ff003159;
$alternate-table-row-color-even_checked: #ffeff2;
$alternate-table-row-color-odd_checked: #ffe8ec;

$primary-table-cell-border-color: #f2f2f2;
$alternate-table-row-color-even: #ffffff;
$alternate-table-row-color-odd: #fbfbfb;

.board-content {
  display: block;
  //  padding-left: 20px;
  //  padding-right: 20px;
  //  padding-top: 20px;
}

.board-narrowed {
  margin-right: 50px;
}

.board-full {
  //  margin-right: 50px;
}

.board-content .top-panel {
  margin: 20px;
}

.board-content .checked-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-family: Medium;
  height: 100%;

  .selected-boks {
    font-size: 18px;
    margin-right: 25px;

    div {
      position: relative;
      font-family: Regular;
      font-size: 15px;
      color: $primary-color;

      span {
        position: absolute;
        top: -5px;
      }

      :hover {
        cursor: pointer;
      }
    }
  }
}

.board-content .tab-pane {
  background-color: white;
  padding: 0px;
  padding-top: 10px;
}

.board-content .nav-tabs .nav-item {
  background-color: white;
  border-radius: 8px 8px 0px 0px;
  margin-right: 3px;
  border: 1px solid $border-color;
  height: 34px;
}

.board-content .nav-tabs .nav-item:first-of-type {
  margin-left: 0px;
}

.board-content .nav-tabs .nav-link {
  font-family: Regular;
  font-size: 16px;
  margin-top: 2px;
  height: 33px;
  color: $inactive-tab-color;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-color: transparent !important;
}

.board-content .nav-tabs .nav-link.active {
  font-family: SemiBold;
  border-radius: 8px 8px 0px 0px;
  color: $active-tab-color;
  font-size: 16px;
  border: 0px;
}

.board-content .primary-table {
  width: 100%;
  font-family: Regular;
  font-size: 14px;
  margin-top: 10px;
  border-collapse: separate;
  border-spacing: 0 0.5px;

  .checkbox-column-header {
    width: 40px;
  }

  .checkbox-column-header:after {
    opacity: 0;
  }

  .checkbox-column {
    width: 40px;
    padding-left: 10px;
  }

  .link-column {
    color: $primary-color;
    cursor: pointer;
  }

  tbody tr:nth-child(even) {
    background-color: $alternate-table-row-color-even;
  }

  tbody tr.checked:nth-child(even) {
    background-color: #f3f3f3;
    //    background-color: $alternate-table-row-color-even_checked;
  }

  tbody tr:nth-child(odd) {
    background-color: $alternate-table-row-color-odd;
  }

  tbody tr.checked:nth-child(odd) {
    background-color: #f3f3f3;
    //    background-color: $alternate-table-row-color-odd_checked;
  }

  thead tr {
    color: $primary-color;

    td {
      padding-left: 10px;
      border-top: 2px solid $primary-table-header-cell-border-color;
      border-bottom: 1px solid $primary-table-header-cell-border-color;
      border-left: 1px solid $primary-table-header-cell-border-color;
      border-right: 1px solid $primary-table-header-cell-border-color;
      height: 43px;
      padding-top: 6px;
      box-shadow: 0 3px 6px #00000005;
      cursor: pointer;
      white-space: nowrap;

      /* Alternative for showing sort icons using flex */
      /* <div class="sortable"><span>Title</span><span class="icon"></span></div> */
      .sortable {
        display: flex;
        align-items: center;
      }

      span:first-child {
        flex-grow: 1;
      }

      span.icon {
        background: url('/content/images/SortArrows.svg') no-repeat center center;
        width: 8px;
        height: 12px;
        margin-right: 5px;
        margin-top: -6px;
      }
      /**/

      &.sortable:after {
        float: right;
        content: url('/content/images/SortArrows.svg');
        margin-right: 5px;
        margin-top: 2px;
      }

      &.sorted-by {
        background-color: $primary-table-header-cell-selected-background-color;
      }
    }

    .two-lines-table-header-cell:after {
      margin-top: -10px;
    }
  }

  tbody {
    font-family: Medium;
  }

  tbody tr {
    td {
      border: 0.5px solid $primary-table-cell-border-color;
      padding-left: $primary-table-cell-padding-left-size;
      padding-right: $primary-table-cell-padding-left-size;
      height: 55px;
      padding-top: 8px;
      box-shadow: 0 3px 6px #00000005;
      white-space: nowrap;
    }
  }

  tbody tr.checked {
    td {
      border: 0.5px solid rgb(224 211 213 / 35%);
      //      border: 0.5px solid $primary-table-cell-border-color_checked;
    }

    td:nth-child(2) {
      color: $primary-color;
    }
  }
}
